import { CloseCircleFilled, CloseOutlined, LockOutlined, UserOutlined } from "@ant-design/icons"
import { Checkbox, Form, Input } from "antd"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { logo_thaco } from "../../components/ExportImgFigma"
import './style.scss'
const usernameTest = 'demo'
const passwordTest = '123'
const Login: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [messeageErro, setMesseageErro] = useState<boolean>(false)
  const [enableLogin, setEnableLogin] = useState<boolean>(false);
  const navigate = useNavigate()
  const handleUsernameChange = (e: any) => {
    setUsername(e.target.value);
    updateEnableLogin(e.target.value, password);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    updateEnableLogin(username, e.target.value);
  };

  const updateEnableLogin = (user: string, pass: string) => {
    setEnableLogin(user.trim() !== '' && pass.trim() !== '');
  };
  const enterLogin = () => {
    if (username === usernameTest && password === passwordTest) {
      navigate('/')
    } else {
      setMesseageErro(true)
    }
  }
  return (
    <div className=" h-100vh d-flex items-center overflow-hidden">
      <div className="bg-login d-flex align-center">
        <div className=" d-flex flex-column justify-between pl-110-px">
          <div className=" d-flex flex-column ">
            <img src={logo_thaco} alt="" className="w-544-px" />
            <div>
              <span className="text-11 fw-600 font-regular text-white line-height-normal">Hệ Thống Quản Lý <br /> Thành Viên</span>
            </div>

          </div>
          <p className="font-regular fw-400 text-white text-sm absolute b-8">Copyright ©2023 Produced by GoTRUST & Bluebolt SoftWare</p>
        </div>
      </div>
      <div className="h-100vh d-flex  align-center pl-58-px">
        <Form className="bg-form-login d-flex  align-center">
          <div className="w-364-px d-flex flex-column gap-6">
            <div className="d-flex flex-column gap-5">
              <span className="text-30-px font-medium fw-500">Đăng nhập</span>
              <div
                className={` ${messeageErro ? 'd-flex align-center rounded-1 border-FFCCD2 bg-FEF2F2 h-57-px' : 'none'}`}
              >
                <div className="d-flex gap-3 align-baseline" >
                  <CloseCircleFilled className="text-sm text-F64C4C pl-3 overflow-auto" />
                  <p className="text-sm text-1F1F1F font-regular fw-400">Đăng nhập thất bại! Kiểm tra lại mật khẩu và email đăng nhập</p>
                </div>
                <CloseOutlined className="text-sm text-8E8E8E pr-3 cursor-pointer" onClick={() => setMesseageErro(false)} />
              </div>
              <div className="d-flex flex-column gap-4">
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Nhập tên đăng nhập hoặc số điện thoại, email!' }]}
                  className="m-none-form-item"
                >

                  <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center gap-3 px-3 py-2-px h-10  overflow-hidden">
                    <UserOutlined className="text-base text-096DD9" />
                    <Input
                      placeholder="Tên đăng nhập hoặc số điện thoại, email"
                      className="bg-FCFCFD place-holder-input-98A2B3 text-base border-none custom-input-focus none-focus-erro font-regular p-none-input hover-none"
                      value={username}
                      onChange={handleUsernameChange}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Nhập mật khẩu của bạn!' }]}
                  className="m-none-form-item"
                >

                  <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center gap-3 px-3 py-2-px h-10  overflow-hidden">
                    <LockOutlined className="text-base text-096DD9" />
                    <Input.Password
                      placeholder="Mật khẩu của bạn"
                      className="bg-FCFCFD place-holder-98A2B3 text-base border-none custom-input-focus none-focus-erro font-regular p-none-input-password input-password-none hover-none font-input"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="d-flex justify-between align-center">
              <Checkbox className="size-check-box size-check-box-inner p-checkbox icon-check-box"><span className="text-sm font-regular fw-400">Lưu tài khoản</span></Checkbox>
              <span className="text-base fw-400 font-regular text-1890FF">Lấy lại mật khẩu</span>
            </div>
            <button
              className={`w-full h-38-px  rounded-2 text-base font-regular fw-400 ${!enableLogin ? 'bg-F5F5F5 text-98A2B3 border-EAECF0 shadow-login' : 'shadow-active bg-1890FF text-white border-none'}`} disabled={!enableLogin}
              onClick={enterLogin}
            >
              Đăng nhập
            </button>
          </div>
        </Form>

      </div>
    </div>
  )
}
export default Login