import { Tag } from 'antd';
import React from 'react'
import './RelativeItem.scss'

interface Props {
  type: string;
  name: string;
  interests: Array<any>;
}

const RelativeItem: React.FC<Props> = ({
  type, name, interests
}) => {
  return (
    <div className="relative-item">
      <div className="relative-item__top">
        <div className="tag">
          <Tag className='rs-tag daybreak-blue no-border'>{ type }</Tag>
        </div>
        <span className="name font-medium">{ name }</span>
      </div>

      <div className="relative-item__bottom">
        <span>Sở thích</span>
        <div className="ant-tags">
          {
            interests.map((item, index) => (
              <Tag
                key={index}
                className='rs-tag golden-purple'
              >{ item }</Tag>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default RelativeItem;