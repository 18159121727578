import { CloseCircleOutlined, DownOutlined, EditOutlined, LinkOutlined, SearchOutlined, StarOutlined, UserOutlined } from "@ant-design/icons"
import { Form, Input, Select } from "antd"
import { arrayRelatives } from "../ArrayDemo"

const InfoRelative: React.FC = () => {
  return (
    <div className="d-flex flex-column align-baseline gap-14-px pt-29-px">
      <div className="d-flex flex-column  w-full gap-3 ">
        <p className="text-344054 text-base fw-500 font-medium pl-3">Thông tin người thân</p>
        <Form.Item
          name="username"
          // rules={[{ required: true, message: 'Nhập họ và tên!' }]}
          className="m-none-form-item"
        >
          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center gap-2 px-3 py-2-px h-10  overflow-hidden">
            <UserOutlined className="text-sm text-344054" />
            <Input
              placeholder="Họ và tên"
              className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-98A2B3 hover-none"
            />
          </div>
        </Form.Item>

        <Form.Item
          name="marri"
          // rules={[{ required: true, message: 'Nhập mối quan hệ!' }]}
          className="m-none-form-item"
        >
          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3  py-2-px h-10 justify-between overflow-hidden">
            <div className="w-full d-flex align-center gap-2">
              <LinkOutlined className="text-sm text-344054" />
              <Select
                showSearch
                placeholder="Mối quan hệ"
                className="w-full  text-left text-span-select text-sm  bg-FCFCFD-ant-select font-regular p-none-select h-8 line-none line-none-item inline-none"
                options={[
                  { value: 'hà nội', label: 'Hà Nội' },
                  { value: 'huế', label: 'Huế' },
                ]}
                suffixIcon={null}
                variant="borderless"
              />
            </div>

            <div className="  h-full d-flex align-center">
              <DownOutlined className="text-sm text-98A2B3 " />
            </div>
          </div>
        </Form.Item>

        <Form.Item
          name="interest"
          // rules={[{ required: true, message: 'Chọn sở thích!' }]}
          className="m-none-form-item"
        >

          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3  py-2-px h-10 justify-between overflow-hidden">
            <div className="w-full d-flex align-center gap-2">
              <StarOutlined className="text-sm text-344054" />
              <Select
                showSearch
                placeholder="Tìm và chọn sở thích"
                className="w-full  text-left text-span-select text-sm  bg-FCFCFD-ant-select font-regular p-none-select h-10 line-none line-none-item inline-none"
                options={[
                  { value: 'hà nội', label: 'Hà Nội' },
                  { value: 'huế', label: 'Huế' },
                ]}
                suffixIcon={null}
                variant="borderless"
              />
            </div>

            <div className="  h-full d-flex align-center">
              <SearchOutlined className="text-sm text-98A2B3" />
            </div>
          </div>
        </Form.Item>
      </div>
      <button className="rounded-2  py-1 w-full bg-white border-1890FF text-1890FF line-height-22-px text-sm font-regular">
        Thêm người thân
      </button>
      <div className="d-flex flex-column gap-10-px w-full">
        {
          arrayRelatives.map((item, id) => (
            <div key={id} className="d-flex flex-column gap-6  rounded-2 border-f2f4f7 py-13-px px-6">
              <div className="d-flex align-center justify-between">
                <div className="d-flex align-center gap-19-px">
                  <p className="rounded-100-px bg-E6F7FF px-2  text-1890FF font-regular text-xs">{item.title}</p>
                  <p className="text-344054 fw-500 font-medium text-sm">{item.name}</p>
                </div>
                <div className="d-flex align-center gap-2  ">
                  <CloseCircleOutlined className="text-F5222D  text-sm rounded-1 shadow-btn p-1 cursor-pointer" />
                  <EditOutlined className="text-sm rounded-1 shadow-btn  p-1 cursor-pointer text-344054" />
                </div>
              </div>
              <div className="d-flex align-center gap-6">
                <p className="font-regular text-sm">Sở thích</p>
                <div className="d-flex align-center gap-2">
                  {
                    item.interest.map((value, index) => (

                      <p key={index} className="rounded-2 bg-F9F0FF px-2 border-D3ADF7 text-722ED1 py-1-px text-xs font-regular">{value.interest}</p>
                    ))
                  }
                </div>

              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
export default InfoRelative