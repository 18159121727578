import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
// import AddGroupCustomers from '../../components/Filter/AddGroupCustomers';
import { Breadcrumb, Input, Checkbox, Slider, DatePicker, Tree, Space, Table, Pagination, ConfigProvider, Select, Switch  } from 'antd';
import type { GetProp, SliderSingleProps, DatePickerProps, TreeDataNode, TreeProps, TableColumnsType, TableProps } from 'antd';
import { DownOutlined, UserOutlined, DeleteOutlined, FilterOutlined, CloseOutlined, DownloadOutlined, UploadOutlined, UserAddOutlined, SettingOutlined, EyeOutlined, EditOutlined, PlusCircleOutlined, GlobalOutlined } from '@ant-design/icons';
import type { SearchProps } from 'antd/es/input/Search';
import viVN from 'antd/locale/vi_VN';
import './CustomerList.scss';
import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';

interface DataType {
  key: React.Key;
  hd: string;
  stt: number;
  name: string;
  cccd: number;
  ns: string;
  gt: string;
  dt: string;
  email: string;
  qt: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Hành động',
    dataIndex: 'hd',
    render: () => (
      <Space size="middle">
        <Link to={'/customer/list/1'}><span><EyeOutlined /></span></Link>
        <Link to={'/customer/update-customer'}><span><EditOutlined /></span></Link>
      </Space>
    ),
  },
  {
    title: 'STT',
    dataIndex: 'stt',
  },
  {
    title: 'Họ và tên',
    dataIndex: 'name',
  },
  {
    title: 'CCCD/CMND',
    dataIndex: 'cccd',
    align: 'right',
  },
  {
    title: 'Ngày sinh',
    dataIndex: 'ns',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
  },
  {
    title: 'Giới tính',
    dataIndex: 'gt',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'dt',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Quan tâm',
    dataIndex: 'qt',
  },
];

const data: DataType[] = [
  {
    key: '1',
    hd: 'John Brown',
    stt: 1,
    name: 'Nguyễn Lê Hải Phong',
    cccd: 381822344,
    ns: '18/09/2016',
    gt: 'Nam',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '2',
    hd: 'John Brown',
    stt: 2,
    name: 'Tuấn Lê',
    cccd: 381820394,
    ns: '18/09/2016',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '3',
    hd: 'John Brown',
    stt: 3,
    name: 'Tuấn Hải',
    cccd: 381820485,
    ns: '07/05/2016',
    gt: 'Nam',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '4',
    hd: 'John Brown',
    stt: 4,
    name: 'Minh Trần',
    cccd: 381820453,
    ns: '28/10/2012',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '5',
    hd: 'John Brown',
    stt: 5,
    name: 'Hoàng Anh',
    cccd: 381824874,
    ns: '18/09/2016',
    gt: 'Nam',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '6',
    hd: 'John Brown',
    stt: 6,
    name: 'Trung Hà',
    cccd: 381823828,
    ns: '12/06/2020',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '7',
    hd: 'John Brown',
    stt: 7,
    name: 'Khánh Hoàn',
    cccd: 381820394,
    ns: '07/05/2016',
    gt: 'Nam',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '8',
    hd: 'John Brown',
    stt: 8,
    name: 'Hạ Trang',
    cccd: 381820453,
    ns: '15/08/2017',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '9',
    hd: 'John Brown',
    stt: 9,
    name: 'Trần Minh Đức',
    cccd: 381820485,
    ns: '16/08/2013',
    gt: 'Nam',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '10',
    hd: 'John Brown',
    stt: 10,
    name: 'Trúc Minh',
    cccd: 381820438,
    ns: '28/10/2012',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '11',
    hd: 'John Brown',
    stt: 11,
    name: 'Trúc Minh',
    cccd: 381820438,
    ns: '28/10/2012',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '12',
    hd: 'John Brown',
    stt: 12,
    name: 'Trúc Minh',
    cccd: 381820438,
    ns: '28/10/2012',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '13',
    hd: 'John Brown',
    stt: 13,
    name: 'Trúc Minh',
    cccd: 381820438,
    ns: '28/10/2012',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '14',
    hd: 'John Brown',
    stt: 14,
    name: 'Trúc Minh',
    cccd: 381820438,
    ns: '28/10/2012',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '15',
    hd: 'John Brown',
    stt: 15,
    name: 'Trúc Minh',
    cccd: 381820438,
    ns: '28/10/2012',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
  {
    key: '16',
    hd: 'John Brown',
    stt: 16,
    name: 'Trúc Minh',
    cccd: 381820438,
    ns: '28/10/2012',
    gt: 'Nữ',
    dt: '039222345',
    email: 'trantuanmy@thaco.com.vn',
    qt: 'BMW',
  },
];

const { Search } = Input;

const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
  console.log('checked = ', checkedValues);
};

const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
  console.log(date, dateString);
};

const onChangeDateSN: DatePickerProps['onChange'] = (date, dateString) => {
  console.log(date, dateString);
};

const treeData: TreeDataNode[] = [
  {
    title: 'Miền Bắc (11.000)',
    key: '0-0',
    children: [
      {
        title: 'Hà Nội (6.000)',
        key: '0-0-0',
      },
      {
        title: 'Hải Phòng (5.000)',
        key: '0-0-1',
      },
    ],
  },
  {
    title: 'Miền Trung (11.200)',
    key: '0-1',
    children: [
      { title: 'Miền Trung1 (11.200)', key: '0-1-0-0' },
      { title: 'Miền Trung2 (11.200)', key: '0-1-0-1' },
    ],
  },
  {
    title: 'Miền Nam (11.500)',
    key: '0-2',
    children: [
      { title: 'Miền Nam1 (11.200)', key: '0-2-0-0' },
      { title: 'Miền Nam2 (11.200)', key: '0-2-0-1' },
    ],
  },
];

const Home: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);

  const [showSelectFilter, setActive] = useState(false);
  const [showSelectAddFilter, setAddActive] = useState(false);
  const [accessSelectFilter, setAccessActive] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const hasSelected = selectedRowKeys.length > 0;

  const setActiveAddFilter = () => {
    setActive(false)
    setAddActive(true)
  };

  const setActiveCloseFilter = () => {
    setAccessActive(true)
  };

  const setActiveHiddenFilter = () => {
    setAccessActive(false)
    setAddActive(false)
  };

  const marks: SliderSingleProps['marks'] = {
    0: '0',
    25: '1 tỷ',
    50: '3 tỷ',
    100: {
      style: {
        color: '#00000073',
      },
      label: '5tỷ',
    },
  };

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const [isTabGroupCustomers, setTabGroupCustomers] = useState(1)
  const [isInfoPopupShow, setInfoPopupShow] = useState(false)
  const handleButtonInfoClick = () => {
    setInfoPopupShow(true)
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onChangeSW = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  return (
    <div className='customer-list'>
      {/* <Helmet>
        <title>THACO AUTO CRM</title>
      </Helmet> */}
      <Layout>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/">Khách hàng</a>,
            },
            {
              title: 'Danh sách khách hàng',
            },
          ]}
        />

        <div className='title-page'>
          Danh sách khách hàng
        </div>

        {/* Fillter block */}
        <div className={`filter-block ${hasSelected ? "active" : ""}`}>
          <div className='search-block'>
            <Search placeholder="input search text" onSearch={onSearch} />
          </div>
          
          <div className='filter-select'>
            <div className='btn-select-show' onClick={() => setActive(true)}>
              <span>Chọn bộ lọc</span>
              <div className="icon-down">
                <DownOutlined />
              </div>
            </div>
            <div className={`list-filter ${showSelectFilter ? "active" : ""}`}>
              <Input placeholder="Search name" />
              <div className="list-item">
                <div className="item-select" onClick={() => setActive(false)}>
                  <div className="icon-left">
                    <UserOutlined />
                    <div className="name-select">Khách SN tháng 9/2023</div>
                  </div>
                  <div className="icon-right">
                    <DeleteOutlined />
                  </div>
                </div>

                <div className="item-select" onClick={() => setActive(false)}>
                  <div className="icon-left">
                    <UserOutlined />
                    <div className="name-select">Khách SN tháng 8/2023</div>
                  </div>
                  <div className="icon-right">
                    <DeleteOutlined />
                  </div>
                </div>

                <div className="item-select" onClick={() => setActive(false)}>
                  <div className="icon-left">
                    <UserOutlined />
                    <div className="name-select">Khách có lịch bảo dưỡng tháng 9/2023</div>
                  </div>
                  <div className="icon-right">
                    <DeleteOutlined />
                  </div>
                </div>

                <div className="item-select" onClick={() => setActive(false)}>
                  <div className="icon-left">
                    <UserOutlined />
                    <div className="name-select">Khách có lịch bảo dưỡng tháng 8/2023</div>
                  </div>
                  <div className="icon-right">
                    <DeleteOutlined />
                  </div>
                </div>

                <div className="item-select" onClick={() => setActive(false)}>
                  <div className="icon-left">
                    <UserOutlined />
                    <div className="name-select">Khách BMW</div>
                  </div>
                  <div className="icon-right">
                    <DeleteOutlined />
                  </div>
                </div>

                <div className="item-select" onClick={() => setActive(false)}>
                  <div className="icon-left">
                    <UserOutlined />
                    <div className="name-select">Thành viên Vàng</div>
                  </div>
                  <div className="icon-right">
                    <DeleteOutlined />
                  </div>
                </div>

                <div className="item-select" onClick={() => setActive(false)}>
                  <div className="icon-left">
                    <UserOutlined />
                    <div className="name-select">Thành viên Bạc</div>
                  </div>
                  <div className="icon-right">
                    <DeleteOutlined />
                  </div>
                </div>

                <div className="item-select" onClick={() => setActive(false)}>
                  <div className="icon-left">
                    <UserOutlined />
                    <div className="name-select">Thành viên Đồng</div>
                  </div>
                  <div className="icon-right">
                    <DeleteOutlined />
                  </div>
                </div>
              </div>
              <div
                className="btn-filter"
                onClick={setActiveAddFilter}
              >
                <div className="icon-btn-filter"><FilterOutlined /></div>
                <div className="text-btn-filter">Bộ lọc</div>
              </div>
            </div>
          </div>

          <div className="btn-input">
            <div className="icon-svg">
              <DownloadOutlined />
            </div>
            <div className="text">Xuất DS</div>
          </div>

          <div className="btn-input">
            <div className="icon-svg">
              <UploadOutlined />
            </div>
            <div className="text">Nhập DS</div>
          </div>

          <div className="btn-input btn-primary">
            <div className="icon-svg">
              <UserAddOutlined />
            </div>
            <Link to="/customer/add-customer" className="text">Thêm khách hàng</Link>
          </div>

          <div className="btn-input t-px-8">
            <div className="icon-svg">
              <SettingOutlined />
            </div>
          </div>
        </div>

        {/* Fillter block */}
        <div className={`filter-block filter-active-show ${hasSelected ? "active" : ""}`}>
          <div className='total-chosse'>
            {hasSelected ? `Đã chọn ${selectedRowKeys.length} khách hàng` : ''}
          </div>

          <div className="btn-input">
            <div className="icon-svg">
              <img src="/customer/cm.svg" alt="" />
            </div>
            <div className="text">Gửi SMS</div>
          </div>

          <div className="btn-input">
            <div className="icon-svg">
              <img src="/customer/email.svg" alt="" />
            </div>
            <div className="text">Gửi Email</div>
          </div>

          <div className="btn-input">
            <div className="icon-svg">
              <img src="/customer/zalo.svg" alt="" />
            </div>
            <div className="text">Gửi ZNS</div>
          </div>

          <div
            className="btn-input"
            onClick={() => handleButtonInfoClick()}
          >
            <div className="icon-svg">
              <img src="/customer/use.svg" alt="" />
            </div>
            <div className="text">Nhóm khách hàng</div>
          </div>

          <div className="btn-input">
            <div className="icon-svg">
              <img src="/customer/down.svg" alt="" />
            </div>
            <div className="text">Xuất DS </div>
          </div>
        </div>

        <div className="table-block">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            onChange={onChangeTB}
            pagination={false}
            showSorterTooltip={false}
          />
          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                total={85}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng cộng ${total} trang`}
                locale={{ items_per_page: 'trang' }}
              />
            </div>
          </ConfigProvider>
        </div>

        <div className={`add-filter ${showSelectAddFilter ? "active" : ""}`}>
          <div className="title-add-filter">Bộ lọc</div>
          
          <div className="content-add-filter t-scrollbar">
            <div className="col-add-filter">
              <div className="one-add-filter-block t-mb-24 t-scrollbar">
                <div className="col-title-add-filter">Thương hiệu sở hữu</div>
                <div className="list-add-filter-content">
                  <Checkbox.Group onChange={onChange}>
                    <Checkbox className="one-add-filter-content" value="A">
                      <div className="img-text">
                        <img src="/customer/bmw.svg" alt="" />
                        <div className="text">BMW (2.000)</div>
                      </div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="B">
                      <div className="img-text">
                        <img src="/customer/kia.svg" alt="" />
                        <div className="text">KIA (1.000)</div>
                      </div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="C">
                      <div className="img-text">
                        <img src="/customer/peu.svg" alt="" />
                        <div className="text">Peugeot (1.123)</div>
                      </div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="D">
                      <div className="img-text">
                        <img src="/customer/maza.svg" alt="" />
                        <div className="text">Mazda (1.142)</div>
                      </div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="E">
                      <div className="img-text">
                        <img src="/customer/mini.svg" alt="" />
                        <div className="text">Mini (1.111)</div>
                      </div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="E1">
                      <div className="img-text">
                        <img src="/customer/mini.svg" alt="" />
                        <div className="text">Mini (1.111)</div>
                      </div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Mức giá xe sở hữu</div>
                <div className="list-add-filter-content slider-price">
                  <Slider range marks={marks} tooltip={{ open: false }} defaultValue={[0, 37]} />
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24 t-pt-16">
                <div className="col-title-add-filter">Lịch bảo dưỡng</div>
                <div className="list-add-filter-content date-picker">
                  <DatePicker popupClassName="t-ctrl-date-picker" onChange={onChangeDate} placeholder={"Chọn ngày bảo dưỡng"} />
                </div>
                <div className="list-select-2col">
                  <Checkbox.Group onChange={onChange}>
                    <Checkbox className="one-add-filter-content" value="Bb2">
                      <div className="text">Hôm nay</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Bb3">
                      <div className="text">Ngày mai</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Bb4">
                      <div className="text">Ngày mốt</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Bb5">
                      <div className="text">Tuần này</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Bb6">
                      <div className="text">Tháng này</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Aa1">
                      <div className="text">Quý này</div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>
            </div>

            <div className="col-add-filter">
              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Giới tính</div>
                <div className="list-select-2col">
                  <Checkbox.Group onChange={onChange}>
                    <Checkbox className="one-add-filter-content" value="Aa">
                      <div className="text">Nam</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Bb">
                      <div className="text">Nữ</div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Sinh nhật sắp tới</div>
                <div className="list-add-filter-content date-picker">
                  <DatePicker popupClassName="t-ctrl-date-picker" onChange={onChangeDateSN} placeholder={"Chọn ngày sinh nhật"} />
                </div>
                <div className="list-select-2col">
                  <Checkbox.Group onChange={onChange}>
                    <Checkbox className="one-add-filter-content" value="Bb21">
                      <div className="text">Hôm nay</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Bb31">
                      <div className="text">Ngày mai</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Bb41">
                      <div className="text">Ngày mốt</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Bb51">
                      <div className="text">Tuần này</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Bb61">
                      <div className="text">Tháng này</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="Aa11">
                      <div className="text">Quý này</div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>

              <div className="one-add-filter-block t-mb-24">
                <div className="col-title-add-filter">Khu vực</div>
                <div className="list-select-2col tree-select">
                  <Tree
                    checkable
                    defaultExpandedKeys={['0-0-0', '0-0-1']}
                    defaultSelectedKeys={['0-0-0', '0-0-1']}
                    defaultCheckedKeys={['0-0-0', '0-0-1']}
                    onSelect={onSelect}
                    onCheck={onCheck}
                    treeData={treeData}
                    defaultExpandAll={true}
                  />
                </div>
              </div>

              <div className="one-add-filter-block">
                <div className="col-title-add-filter">Hạng thành viên</div>
                <div className="list-add-filter-content">
                  <Checkbox.Group onChange={onChange}>
                    <Checkbox className="one-add-filter-content" value="111">
                      <div className="text">Thành viên Vàng</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="211">
                      <div className="text">Thành viên Bạc</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="311">
                      <div className="text">Thành viên Đồng</div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>
            </div>

            <div className="col-add-filter">
              <div className="one-add-filter-block">
                <div className="col-title-add-filter">Thuộc tính khác</div>
                <div className="list-add-filter-content">
                  <Checkbox.Group onChange={onChange}>
                    <Checkbox className="one-add-filter-content" value="1">
                      <div className="text">Thuộc tính 1</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="2">
                      <div className="text">Thuộc tính 1</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="3">
                      <div className="text">Thuộc tính 1</div>
                    </Checkbox>
                    <Checkbox className="one-add-filter-content" value="4">
                      <div className="text">Thuộc tính 1</div>
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-filter-block">
            <div className="btn-block">
              <div className="btn btn-df" onClick={() => setActiveCloseFilter()}>
                <img src="/customer/wrapper.svg" alt="" />
                <div className="text">
                  Lưu bộ lọc
                </div>
              </div>

              <div className="btn btn-primary" onClick={() => setAddActive(false)}>
                <div className="icon-svg"><FilterOutlined /></div>
                <div className="text">
                  Áp dụng lọc
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`show-access-filter ${accessSelectFilter ? "active" : ""}`}>
          <div className="title-access">
            <span>Lưu bộ lọc</span>
            <div className="icon-close">
              <CloseOutlined />
            </div>
          </div>
          <div className="input-add-name">
            <Input placeholder="Nhập tên bộ lọc" />
          </div>
          <div className="btn-footer-access">
            <div className="btn btn-primary" onClick={() => setActiveHiddenFilter()}>
              Lưu
            </div>
          </div>
        </div>

        {/* form add group customers 1 */}
        {
        isInfoPopupShow
          ? <div
              className="group-customers-block"
            >
              <div className="title-access">
                <span>Nhóm khách hàng</span>
                <div className="icon-close" onClick={() => setInfoPopupShow(false)}>
                  <CloseOutlined />
                </div>
              </div>
              <div className="modal-content">
                <div className="title-content">Thêm vào nhóm có sẵn</div>
                <Select
                  className='t-select-ctrl'
                  defaultValue="Nhóm khách hàng 25-40 tuổi"
                  onChange={handleChange}
                  popupClassName='t-ctrl-popup-select'
                  options={[
                    { value: 1, label: 'Nhóm khách hàng 25-40 tuổi' },
                    { value: 2, label: 'Nhóm khách hàng 12-24 tuổi' },
                    { value: 3, label: 'Nhóm khách hàng 41-45 tuổi' },
                    { value: 4, label: 'Nhóm khách hàng 46-80 tuổi' },
                  ]}
                />
                <Link className="link-block" rel="stylesheet" to="/" >
                  <div className="icon-svg"><PlusCircleOutlined /></div>
                  <div className="title">Hoặc tạo mới nhóm khách hàng</div>
                </Link>
                <div className="switch-block">
                  <div className="title">Tự động thêm khách hàng có cùng điều kiện</div>
                  <Switch defaultChecked onChange={onChangeSW} />
                </div>
              </div>
              <div className="btn-footer-access">
                <div className="btn btn-primary" onClick={() => setInfoPopupShow(false)}>
                  Lưu
                </div>
              </div>
            </div>
          : null
        }

        {/* form add group customers 2 */}
        {/* {
        isInfoPopupShow
          ? <div
              className="group-customers-block"
            >
              <div className="title-access">
                <span>Tạo nhóm khách hàng</span>
                <div className="icon-close" onClick={() => setInfoPopupShow(false)}>
                  <CloseOutlined />
                </div>
              </div>
              <div className="modal-content">
                <div className="list-tab">
                  <div
                    className={`one-item tab-left ${isTabGroupCustomers === 1 ? "active" : ""}`}
                    onClick={() => setTabGroupCustomers(1)}
                  >
                    <div className="icon-svg"><UserOutlined /></div>
                    <div className="title">Nhóm riêng tư</div>
                  </div>
                  <div
                    className={`one-item tab-right ${isTabGroupCustomers === 2 ? "active" : ""}`}
                    onClick={() => setTabGroupCustomers(2)}
                  >
                    <div className="icon-svg"><GlobalOutlined /></div>
                    <div className="title">Nhóm có thể chia sẻ</div>
                  </div>
                </div>
                <div className="input-add-name">
                  <Input placeholder="Nhập tên bộ lọc" />
                </div>
                <div className="text-area-block">
                  <TextArea
                    placeholder="Giới thiệu về nhóm này"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </div>
                <div className="search-tag">
                  <div className='search-block'>
                    <Search placeholder="Phân loại nhóm khách hàng bằng thẻ tag" onSearch={onSearch} />
                  </div>
                  <div className="list-tags">
                    <div className="one-tag">
                      <div className="title">Quảng cáo</div>
                      <div className="icon-close">
                        <CloseOutlined />
                      </div>
                    </div>

                    <div className="one-tag">
                      <div className="title">Chăm sóc</div>
                      <div className="icon-close">
                        <CloseOutlined />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="switch-block">
                  <div className="title">Tự động thêm khách hàng có cùng điều kiện</div>
                  <Switch defaultChecked onChange={onChangeSW} />
                </div>
              </div>
              <div className="btn-footer-access two-btn">
                <div className="btn-df">Làm mới thông tin</div>
                <div className="btn btn-primary" onClick={() => setInfoPopupShow(false)}>
                  Lưu
                </div>
              </div>
            </div>
          : null
        } */}

      </Layout>
    </div>
  );
}

export default Home;