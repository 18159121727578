import { CalendarOutlined, CloseOutlined, CompassOutlined, DownOutlined, HomeOutlined, PhoneOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { DatePicker, Form, Input, Radio, Select } from "antd"
import { useState } from "react"
import { man_icon, woman_icon } from "../../../../../components/ExportImgFigma"
import './style.scss'
const selectTag = [
  { value: 'Doanh nghiệp', label: 'Doanh nghiệp' },
  { value: 'Khó tính', label: 'Khó tính' },
  { value: 'Giàu có', label: 'Giàu có' }
]

const selectInterest = [
  { value: 'Doanh nghiệp', label: 'Doanh nghiệp' },
  { value: 'Khó tính', label: 'Khó tính' },
  { value: 'Giàu có', label: 'Giàu có' }
]
const selectTypeCar = [
  { value: 'Doanh nghiệp', label: 'Doanh nghiệp' },
  { value: 'Khó tính', label: 'Khó tính' },
  { value: 'Giàu có', label: 'Giàu có' }
]
const InfoPersonal: React.FC = () => {
  const [valuesTag, setValuesTag] = useState<string[]>([]);
  const [valuesInterest, setValuesInterest] = useState<string[]>([]);
  const [valuesCar, setValuesCar] = useState<string[]>([]);
  const [openTag, setOpenTag] = useState(false);
  const [openInterest, setOpenInterest] = useState(false);
  const [openCar, setOpenCar] = useState(false);
  const [sex, setSex] = useState('')
  const [marri, setMarri] = useState('')
  const handleChangeTag = (selectedValues: string[]) => {
    setValuesTag(selectedValues)
    setOpenTag(false)
  };
  const handleChangeInterest = (selectedValues: string[]) => {
    setValuesInterest(selectedValues);
    setOpenInterest(false)
  };
  const handleChangevalueCar = (selectedValues: string[]) => {
    setValuesCar(selectedValues);
    setOpenCar(false)
  }
  return (
    <div className="d-flex flex-column align-baseline gap-3 pt-29-px">
      <div className="d-flex flex-column w-full gap-10-px">
        <p className="text-344054 text-base fw-500 font-medium">Thông tin cá nhân</p>
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Nhập họ và tên!' }]}
          className="m-none-form-item"
        >

          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center gap-2 px-3 py-2-px h-10  overflow-hidden">
            <UserOutlined className="text-sm text-344054" />
            <Input
              placeholder="Họ và tên"
              className="bg-FCFCFD place-holder-input-98A2B3 text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input hover-none"
            />
          </div>
        </Form.Item>
        <Form.Item
          name="sex"
          rules={[{ required: true, message: 'Chọn giới tính!' }]}
          className="m-none-form-item"
        >
          <Radio.Group value={sex} onChange={(e) => setSex(e.target.value)} className="w-full ">
            <Radio.Button value='male' className="w-half h-8 border-start-radio p-none-wrapper" >
              <div className="d-flex align-center gap-2 h-8 px-3">
                <img src={man_icon} className="w-14-px h-14-px" alt="" />
                <p className="text-sm font-regular">Nam</p>
              </div>

            </Radio.Button>
            <Radio.Button value='female' className="w-half h-8 border-end-radio p-none-wrapper">
              <div className="d-flex align-center gap-2 h-8  px-3">
                <img src={woman_icon} className="w-14-px h-14-px" alt="" />
                <p className="text-sm font-regular">Nữ</p>
              </div>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="marri"
          rules={[{ required: true, message: 'Chọn tình trạng hôn nhân!' }]}
          className="m-none-form-item"
        >
          <Radio.Group value={marri} onChange={(e) => setMarri(e.target.value)} className="w-full">
            <Radio.Button value='single' className="w-half h-8 border-start-radio p-none-wrapper">
              <div className="d-flex justify-start text-sm h-8 align-center font-regular px-4">
                Độc thân
              </div>

            </Radio.Button>
            <Radio.Button value='Married' className="w-half h-8 border-end-radio p-none-wrapper">
              <div className="d-flex justify-start text-sm h-8 align-center font-regular px-4">
                Đã kết hôn
              </div>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[{ required: true, message: 'Nhập số điện thọai!' }]}
          className="m-none-form-item"
        >
          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3 py-2-px h-10 gap-2  overflow-hidden">
            <PhoneOutlined className="text-sm text-344054" />
            <Input
              placeholder="Số điện thoại"
              className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-98A2B3 hover-none"
            />
          </div>
        </Form.Item>
        <Form.Item
          name="birth"
          rules={[{ required: true, message: 'Chọn ngày tháng năm sinh!' }]}
          className="m-none-form-item"
        >
          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3 py-2-px gap-2 h-10">
            <CalendarOutlined className="text-sm text-344054" />
            <DatePicker
              placeholder="Ngày/Tháng/Năm sinh"
              variant="borderless"
              className="w-full text-input font-regular fw-400 text-sm p-none-picker "
              popupClassName="t-ctrl-date-picker"
              suffixIcon={false}
            // open={true}
            />
          </div>
        </Form.Item>
        <Form.Item
          name="area"
          rules={[{ required: true, message: 'Chọn tỉnh/thành phố!' }]}
          className="m-none-form-item"
        >

          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3  py-2-px h-10 justify-between overflow-hidden">
            <div className="d-flex align-center  w-full">
              <CompassOutlined className="text-sm pr-2 text-344054" />
              <Select
                showSearch
                placeholder="Tỉnh/Thành phố"
                className="w-full  text-left text-span-select text-sm  bg-FCFCFD-ant-select font-regular p-none-select h-10 line-none line-none-item inline-none"
                options={[
                  { value: 'hà nội', label: 'Hà Nội' },
                  { value: 'huế', label: 'Huế' },
                ]}
                suffixIcon={null}
                variant="borderless"
              />

            </div>
            <div className="  h-full d-flex align-center">
              <DownOutlined className="text-sm text-98A2B3 " />
            </div>
          </div>
        </Form.Item>

        <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3 py-2-px h-10 gap-2  overflow-hidden">
          <HomeOutlined className="text-sm text-344054" />
          <Input
            placeholder="Địa chỉ (Không bắt buộc)"
            className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-98A2B3 hover-none"
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-3 w-full">
        <p className="text-344054 text-base fw-500 font-medium">Nhóm</p>
        <Form.Item
          name="phone"
          rules={[{ required: true, message: 'Tìm nhóm' }]}
          className="w-full m-none-form-item"
        >
          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3  py-2-px h-10 justify-between overflow-hidden">
            <Input
              placeholder="Tìm và chọn nhóm"
              className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-98A2B3 hover-none"
            />
            <div className=" border-l-F2F4F7 h-full d-flex align-center">
              <SearchOutlined className="text-sm text-98A2B3 pl-3" />
            </div>
          </div>
        </Form.Item>
      </div>

      <div className="d-flex flex-column gap-3 w-full ">
        <p className="text-344054 text-base fw-500 text-left font-medium">Phân loại theo thẻ tag</p>
        <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-2 pr-3  py-2-px h-10 justify-between overflow-hidden">
          <Select
            showSearch
            mode="multiple"
            placeholder="Tìm thẻ tag và chọn"
            className="w-full d-none-span text-left text-span-select text-sm   bg-FCFCFD-ant-select font-regular p-none-select "
            value={valuesTag}
            options={selectTag}
            onChange={handleChangeTag}
            onDropdownVisibleChange={setOpenTag}
            open={openTag}
            variant="borderless"
            suffixIcon={null}
          />
          <div className=" border-l-F2F4F7 h-full d-flex align-center">
            <SearchOutlined className="text-sm text-98A2B3 pl-3" />
          </div>
        </div>

        <div className={`${valuesTag.length !== 0 ? ' d-flex align-center gap-2' : 'none'}`}>
          {valuesTag.map((value, index) => (
            <p key={index} className="bg-F0F5FF  border-ADC6FF rounded-2 text-2F54EB text-xs px-2 d-flex align-center gap-3-px py-1 font-regular">
              {value}
              <CloseOutlined className="text-84878C text-xs cursor-pointer" onClick={() => setValuesTag(valuesTag.filter(v => v !== value))} />
            </p>
          ))}

        </div>
      </div>
      <div className="d-flex flex-column gap-3 w-full ">
        <p className="text-344054 text-base fw-500 text-left font-medium">Sở thích</p>
        <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-2 pr-3  py-2-px h-10 justify-between overflow-hidden">
          <Select
            showSearch
            mode="multiple"
            placeholder="Tìm thẻ tag và chọn"
            className="w-full d-none-span text-left text-span-select text-sm bg-FCFCFD-ant-select font-regular p-none-select "
            value={valuesInterest}
            options={selectInterest}
            onChange={handleChangeInterest}
            onDropdownVisibleChange={setOpenInterest}
            open={openInterest}
            suffixIcon={null}
            variant="borderless"
          />
          <div className=" border-l-F2F4F7 h-full d-flex align-center">
            <DownOutlined className="text-sm text-98A2B3 pl-3" />
          </div>
        </div>

        <div className={` ${valuesInterest.length !== 0 ? 'd-flex align-center gap-2' : 'none'}`}>
          {valuesInterest.map((value, index) => (
            <p key={index} className="bg-F0F5FF  border-ADC6FF rounded-2 text-2F54EB text-xs px-2 d-flex align-center gap-3-px py-1 font-regular">
              {value}
              <CloseOutlined className="text-84878C text-xs cursor-pointer" onClick={() => setValuesInterest(valuesInterest.filter(v => v !== value))} />
            </p>
          ))}

        </div>
      </div>
      <div className="d-flex flex-column gap-3 w-full ">
        <p className="text-344054 text-base fw-500 text-left font-medium">Loại xe quan tâm</p>
        <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-2 pr-3  py-2-px h-10 justify-between overflow-hidden">
          <Select
            showSearch
            mode="multiple"
            placeholder="Tìm thẻ tag và chọn"
            className="w-full d-none-span text-left text-span-select text-sm bg-FCFCFD-ant-select font-regular p-none-select "
            value={valuesCar}
            options={selectTypeCar}
            onChange={handleChangevalueCar}
            onDropdownVisibleChange={setOpenCar}
            open={openCar}
            suffixIcon={null}
            variant="borderless"
          />
          <div className=" border-l-F2F4F7 h-full d-flex align-center">
            <DownOutlined className="text-sm text-98A2B3 pl-3" />
          </div>
        </div>

        <div className={` ${valuesCar.length !== 0 ? 'd-flex align-center gap-2' : 'none'}`}>
          {valuesCar.map((value, index) => (
            <p key={index} className="bg-F0F5FF  border-ADC6FF rounded-2 text-2F54EB text-xs px-2 d-flex align-center gap-3-px py-1 font-regular">
              {value}
              <CloseOutlined className="text-84878C text-xs cursor-pointer" onClick={() => setValuesCar(valuesCar.filter(v => v !== value))} />
            </p>
          ))}
        </div>
      </div>

    </div>
  )
}
export default InfoPersonal