import React, { useEffect } from 'react';
import Layout from '../../Layout';

const ClassList: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);
  return (
    <Layout>
      <div>Danh sách hạng</div>
    </Layout>
  );
}

export default ClassList;