import React, { useEffect } from 'react';
import Layout from '../../Layout';
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import './CustomerDetail.scss'
import InformationTab from './Tabs/Information';
import InteractiveHistoryTab from './Tabs/InteractiveHistory';
import CareFeedbackTab from './Tabs/CareFeedback';
import SourceTab from './Tabs/Source';

const tabItems: TabsProps['items'] = [
  {
    label: 'Thông tin',
    key: 'information',
    children: <InformationTab />,
  },
  {
    label: 'Lịch sử tương tác',
    key: 'interactive-history',
    children: <InteractiveHistoryTab />
  },
  {
    label: 'Chăm sóc / phản hồi',
    key: 'care-feedback',
    children: <CareFeedbackTab />,
  },
  {
    label: 'Nguồn',
    key: 'source',
    children: <SourceTab />,
  },
];

const CustomerDetail: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);
  return (
    <Layout contentClassName='customer-detail-content'>
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: <Link to="/customer/list">Khách hàng</Link>, },
          { title: <Link to="/customer/list">Danh sách khách hàng</Link>, },
          { title: 'Thông tin khách hàng', },
        ]}
        title='Thông tin khách hàng'
        backLink='/customer/list'
      />

      <Tabs
        defaultActiveKey="information"
        items={tabItems}
        className='rs-tabs'
      />
    </Layout>
  );
}

export default CustomerDetail;