import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Analysis Report Pages
import MarketingCampaigns from './pages/AnalysisReport/MarketingCampaigns'
import Customers from './pages/AnalysisReport/Customers'
import VIPCustomerCare from './pages/AnalysisReport/VIPCustomerCare';
import CustomerCare from './pages/AnalysisReport/CustomerCare';
import Voucher from './pages/AnalysisReport/Voucher';

// Customers Pages
import CustomerList from './pages/Customers/CustomerList';
import CustomerDetail from './pages/Customers/CustomerDetail';
import CustomerGroup from './pages/Customers/CustomerGroup';
import PoolingData from './pages/Customers/PoolingData';

// Member Card Pages
import CardIssuance from './pages/MemberCard/CardIssuance';
import ClassList from './pages/MemberCard/ClassList';
import ClassBenefits from './pages/MemberCard/ClassBenefits';

// Vehicle Management Pages
import VehicleList from './pages/VehicleManagement/VehicleList';
import BrandManagement from './pages/VehicleManagement/BrandManagement';
import VehicleTypeManagement from './pages/VehicleManagement/VehicleTypeManagement';
import VehicleVersionManagement from './pages/VehicleManagement/VehicleVersionManagement';

// Marketing Pages
import CampaignSendsNotifications from './pages/Marketing/CampaignSendsNotifications';
import NotificationTemplatesManagement from './pages/Marketing/NotificationTemplatesManagement';
import NotificationFeesSetting from './pages/Marketing/NotificationFeesSetting';
import VouchersManagement from './pages/Marketing/VouchersManagement';
import VoucherSearch from './pages/Marketing/VoucherSearch';

// Departmental Unit Pages
import Nationwide from './pages/DepartmentalUnit/Nationwide';
import Regions from './pages/DepartmentalUnit/Regions';
import Units from './pages/DepartmentalUnit/Units';
import Departments from './pages/DepartmentalUnit/Departments';

// System Configuration Pages
import AccountsManagement from './pages/SystemConfiguration/AccountsManagement';
import AccountRole from './pages/SystemConfiguration/AccountRole';
import GeneralConfiguration from './pages/SystemConfiguration/GeneralConfiguration';

// Page Not Found
import NotFound from './pages/NotFound';
import AddCustomer from './pages/Customers/Component/AddCustomer';
import UpdateCustomer from './pages/Customers/Component/UpdateCustomer';
import Login from './pages/Login';


const App: React.FC = () => {
  return (
    <Router>
      <Routes>


        <Route path="/login" element={<Login />} />

        {/* Analysis Report Pages */}
        {/* <Route path="/analysis-report/marketing-campaigns" element={<MarketingCampaigns />} /> */}
        <Route path="/" element={<MarketingCampaigns />} key="asdasdadasd" />
        <Route path="/analysis-report/customers" element={<Customers />} />
        <Route path="/analysis-report/vip-customer-care" element={<VIPCustomerCare />} />
        <Route path="/analysis-report/customer-care" element={<CustomerCare />} />
        <Route path="/analysis-report/voucher" element={<Voucher />} />

        {/* Customer Pages */}
        <Route path="/customer/list" element={<CustomerList />} />
        <Route path="/customer/list/:id" element={<CustomerDetail />} />
        <Route path="/customer/add-customer" element={<AddCustomer />} />
        <Route path="/customer/update-customer" element={<UpdateCustomer />} />
        <Route path="/customer/group" element={<CustomerGroup />} />
        <Route path="/customer/pooling-data" element={<PoolingData />} />

        {/* Member Card Pages */}
        <Route path="/member-card/card-issuance" element={<CardIssuance />} />
        <Route path="/member-card/class-list" element={<ClassList />} />
        <Route path="/member-card/class-benefits" element={<ClassBenefits />} />

        {/* Vehicle Management Pages */}
        <Route path="/vehicle-management/vehicle-list" element={<VehicleList />} />
        <Route path="/vehicle-management/brand-management" element={<BrandManagement />} />
        <Route path="/vehicle-management/vehicle-type-management" element={<VehicleTypeManagement />} />
        <Route path="/vehicle-management/vehicle-version-management" element={<VehicleVersionManagement />} />

        {/* Marketing Pages */}
        <Route path="/marketing/campaign-sends-notifications" element={<CampaignSendsNotifications />} />
        <Route path="/marketing/notification-templates-management" element={<NotificationTemplatesManagement />} />
        <Route path="/marketing/notification-fees-setting" element={<NotificationFeesSetting />} />
        <Route path="/marketing/vouchers-management" element={<VouchersManagement />} />
        <Route path="/marketing/voucher-search" element={<VoucherSearch />} />

        {/* Departmental Unit Pages */}
        <Route path="/departmental-unit/nationwide" element={<Nationwide />} />
        <Route path="/departmental-unit/regions" element={<Regions />} />
        <Route path="/departmental-unit/units" element={<Units />} />
        <Route path="/departmental-unit/departments" element={<Departments />} />

        {/* System Configuration Pages */}
        <Route path="/system-configuration/accounts-management" element={<AccountsManagement />} />
        <Route path="/system-configuration/account-role" element={<AccountRole />} />
        <Route path="/system-configuration/general-configuration" element={<GeneralConfiguration />} />

        {/* Not Found Page */}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;