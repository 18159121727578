import React from "react";
import { Tag } from "antd";
import './VehicleItem.scss'

const InterestedVehicle: React.FC = () => {
  return (
    <div className="vehicle-item vehicle-item--interested">
      <div className="vehicle-item__info">
        <img src="/images/car.svg" alt="BMW-Series-7" className="img" />
        <div className="content">
          <div className="top">
            <img src="/images/logo-partner.svg" alt="BMW" className="logo" />
            <div className="item">BMW Series 7</div>
            <div className="item">Phiên bản 10.5</div>
          </div>
          <div className="bottom">
            <Tag className='rs-tag neutral-gray'>Sedan - 4 Chỗ</Tag>
          </div>
        </div>
      </div>
      <img src="/images/brand-icon--purple.svg" alt="THACO AUTO" className="vehicle-item__brand-icon" />
    </div>
  )
}

export default InterestedVehicle