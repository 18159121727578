import React from "react";
import { Button, Tag } from "antd";
import './VehicleItem.scss'

interface Props {
  onButtonClick: (info: boolean) => void;
}

const OwnedVehicle: React.FC<Props> = ({ onButtonClick }) => {
  const handleClick = () => {
    onButtonClick(true)
  };
  return (
    <div className="vehicle-item vehicle-item--owned">
      <div className="vehicle-item__info">
        <img src="/images/car.svg" alt="BMW-Series-7" className="img" />
        <div className="content">
          <div className="top">
            <img src="/images/logo-partner.svg" alt="BMW" className="logo" />
            <div className="item">BMW Series 7</div>
            <div className="item">Phiên bản 10.5</div>
            <div className="item">54H - 84383</div>
          </div>
          <div className="bottom">
            <Tag className='rs-tag neutral-gray'>Sedan - 4 Chỗ</Tag>
            <Tag className='rs-tag neutral-gray'>Sở hữu 08/08/2023</Tag>
          </div>
        </div>
      </div>
      <div className="vehicle-item__right">
        <Button
          className='rs-button'
          onClick={handleClick}
        >Chi tiết thông tin</Button>
      </div>
      <img src="/images/brand-icon--blue.svg" alt="THACO AUTO" className="vehicle-item__brand-icon" />
    </div>
  )
}

export default OwnedVehicle