import { Badge, Button } from 'antd';
import React from 'react';
import './Source.scss';
import { EditFilled } from '@ant-design/icons';

const SourceTab: React.FC = () => {
  return (
    <div className="source-tab">
      <div className="source-tab__col">
        <div className="source-tab__col-content">
          <div className="source-tab__col-title font-medium">Thông tin cá nhân</div>
          <div className="item">
            <span>Tình trạng</span>
            <div className="right">
              <Badge status="success" />
              <span className="neutral-gray-400">Đã gộp</span>
            </div>
          </div>
          <div className="item">
            <span>Tình trạng hôn nhân</span>
            <div className="right">
              <span className="neutral-gray-400">Đã kết hôn</span>
            </div>
          </div>
          <div className="item">
            <span>Nguồn</span>
            <div className="right">
              <span className="neutral-gray-400">DDMS - Sales</span>
            </div>
          </div>
          <div className="item">
            <span>Thuộc</span>
            <div className="right">
              <span className="neutral-gray-400">Đại lý bán hàng ABC</span>
            </div>
          </div>
          <div className="item">
            <span>Nghề nghiệp</span>
            <div className="right">
              <span className="neutral-gray-400">Chưa cập nhật</span>
            </div>
          </div>
          <div className="item">
            <span>Ngày sinh</span>
            <div className="right">
              <span className="neutral-gray-400">08/08/1997</span>
            </div>
          </div>
          <div className="item">
            <span>Giới tính</span>
            <div className="right">
              <span className="neutral-gray-400">Nam</span>
            </div>
          </div>
          <div className="item">
            <span>Số điện thoại</span>
            <div className="right">
              <span className="neutral-gray-400">0977721242</span>
            </div>
          </div>
          <div className="item">
            <span>Email</span>
            <div className="right">
              <span className="neutral-gray-400">Hoangkimquoc97@gmail.com</span>
            </div>
          </div>
          <div className="item">
            <span>Địa chỉ</span>
            <div className="right">
              <span className="neutral-gray-400">245/1 Hoàng Văn Thụ, Tân Bình, TPHCM</span>
            </div>
          </div>
        </div>
      </div>

      <div className="source-tab__col">
        <div className="source-tab__col-head">
          <Button
            type="primary"
            className='rs-button'
          >
            Cập nhật
            <EditFilled />
          </Button>
        </div>

        <div className="source-tab__col-content">
          <div className="source-tab__col-title font-medium">Thông tin cá nhân</div>
          <div className="item">
            <span>Tình trạng</span>
            <div className="right">
              <Badge status="success" />
              <span className="neutral-gray-400">Đã gộp</span>
            </div>
          </div>
          <div className="item">
            <span>Tình trạng hôn nhân</span>
            <div className="right">
              <span className="neutral-gray-400">Đã kết hôn</span>
            </div>
          </div>
          <div className="item">
            <span>Nguồn</span>
            <div className="right">
              <span className="neutral-gray-400">IPPC</span>
            </div>
          </div>
          <div className="item">
            <span>Thuộc</span>
            <div className="right">
              <span className="neutral-gray-400">Đại lý bán hàng ABC</span>
            </div>
          </div>
          <div className="item">
            <span>Nghề nghiệp</span>
            <div className="right">
              <span className="neutral-gray-400">Chưa cập nhật</span>
            </div>
          </div>
          <div className="item">
            <span>Ngày sinh</span>
            <div className="right">
              <span className="neutral-gray-400">08/08/1997</span>
            </div>
          </div>
          <div className="item">
            <span>Giới tính</span>
            <div className="right">
              <span className="neutral-gray-400">Nam</span>
            </div>
          </div>
          <div className="item">
            <span>Số điện thoại</span>
            <div className="right">
              <span className="neutral-gray-400">0977721241</span>
            </div>
          </div>
          <div className="item">
            <span>Email</span>
            <div className="right">
              <span className="neutral-gray-400">Hoangkimquoc97@gmail.com</span>
            </div>
          </div>
          <div className="item">
            <span>Địa chỉ</span>
            <div className="right">
              <span className="neutral-gray-400">245/1 Hoàng Văn Thụ, Tân Bình, TPHCM</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SourceTab;