import { Form, Progress } from "antd"
import { useState } from "react"
import { Link } from "react-router-dom"
import BreadcrumbBlock from "../../../../components/BreadcrumbBlock/BreadcrumbBlock"
import Layout from "../../../../Layout"
import InfoPersonal from "./Component/InfoPersonal"
import InfoRelative from "./Component/InfoRelative"
import './style.scss'

const AddCustomer: React.FC = () => {

  const [submit, setSubmit] = useState(true)

  return (
    <Layout>
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: <Link to="/customer/list">Khách hàng</Link>, },
          { title: <Link to="/customer/list">Danh sách khách hàng</Link>, },
          { title: 'Thêm mới khách hàng', },
        ]}
        title='Thêm mới khách hàng'
        backLink='/customer/list'
      />
      <div className="d-flex justify-center py-6 ">
        <div className="d-flex flex-column gap-34-px w-500-px">
          <p className="text-344054 text-sm fw-600 text-center font-bold">Thông tin cơ bản của khách hàng</p>
          <Form >
            <div className="d-flex align-center gap-6">
              <div className={`d-flex align-center  ${submit ? "gap-2" : ''}`}>
                {submit
                  ? (
                    <Progress
                      percent={40}
                      className={`d-flex align-center custom-progress w-255-px h-progress text-progress d-none-block-progress bg-progress-slide gap-2 h-progress-outer`}

                    />
                  )
                  : (
                    <Progress
                      percent={100}
                      className={`d-flex align-center custom-progress w-378-px h-progress text-progress d-none-block-progress size-check-circle w-none-progress gap-2  h-progress-outer`}
                    />
                  )}
                <p className="text-667085 text-sm font-regular"> {submit ? 'hoàn thiện thông tin' : ''} </p>
              </div>
              <button className="text-white text-base py-1 px-4 rounded-7-px  fw-500 bg-096DD9 border-none outline-none line-height-6 font-regular"
                onClick={() => setSubmit(!submit)}
              >
                {submit ? 'Tiếp theo' : 'Xác nhận'}
              </button>
            </div>
            {
              submit ? (<InfoPersonal />) : (<InfoRelative />)
            }
          </Form>
        </div>
      </div>
    </Layout>
  )
}
export default AddCustomer