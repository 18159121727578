import React from "react";
import RightPopup from "../../../components/Popup/RightPopup";
import './WarrantyHistoryPopup.scss';
import { Button, DatePicker, Pagination } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import WarrantyHistoryItem from "../../../components/WarrantyHistory/WarrantyHistoryItem";
import WarrantyHistoryVertical from "../../../components/WarrantyHistory/WarrantyHistoryVetical";

const { RangePicker } = DatePicker;

interface Props {
  className?: string;
  onClose?: () => void;
  onBack?: () => void;
}

const WarrantyHistoryPopup: React.FC<Props> = ({ className, onClose, onBack }) => {
  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleOnBack = () => {
    if (onBack) {
      onBack()
    }
  }

  const onDateChange = (date: any, dateString: any, info: any) => {
    // console.log(date, dateString, info);
  }

  const warrantyItems = [
    {
      type: 'Bảo hành',
      title: 'Phiếu RO#128484',
      info: {
        type: 'KIA Morning',
        bks: '01K1234',
        vin: '01K1234',
        carId: 'RN2KW5711NM009876',
        id: '381822344',
        maintenanceDay: '13:00 23/03/2023',
        finishDay: '13:00 23/03/2023',
      },
    },
    {
      type: 'Bảo dưỡng',
      title: 'Phiếu RO#128484',
      info: {
        type: 'KIA Morning',
        bks: '01K1234',
        vin: '01K1234',
        carId: 'RN2KW5711NM009876',
        id: '381822344',
        maintenanceDay: '13:00 23/03/2023',
        finishDay: '13:00 23/03/2023',
      },
    }
  ]

  return (
    <RightPopup
      title="Lịch sử bảo hành - bảo dưỡng"
      className={className}
      contentClassName="warranty-history-popup__content"
      onClose={handleOnClose}
    >
      <Button
        block
        className="rs-button primary-outline"
        onClick={handleOnBack}
      >
        <LeftOutlined />
        <span>Quay lại</span>
      </Button>

      <RangePicker
        className="rs-range-picker"
        popupClassName="rs-range-picker-dropdown"
        onCalendarChange={ onDateChange }
      />

      <WarrantyHistoryVertical>
        {
          warrantyItems.map((item, index) => (
            <WarrantyHistoryItem
              key={index}
              type={item.type}
              title={item.title}
              info={item.info}
            />
          ))
        }
      </WarrantyHistoryVertical>
      
      <div className="warranty-history-popup__footer">
        <Pagination
          simple
          defaultCurrent={1}
          total={50}
          className="rs-pagination"
        />
      </div>
    </RightPopup>
  )
}

export default WarrantyHistoryPopup