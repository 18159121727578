export const arrayPitiful = [
    {
        id: 1,
        name: 'BMW',
        number: '2.000'
    },
    {
        id: 2,
        name: 'KIA',
        number: '1.000'
    },
    {
        id: 3,
        name: 'Peugeot',
        number: '1.123'
    },
    {
        id: 4,
        name: 'Peugeot',
        number: '1.123'
    }
]
export const arrayRank = [
    {
        id: 1,
        title: 'Thành viên Vàng',

    },
    {
        id: 2,
        title: 'Thành viên bạc',

    },
    {
        id: 3,
        title: 'Thành viên đồng',

    },

]
export const arrayMemberShip = [
    {
        id: 1,
        title: 'Khách mới',
        number: '1.111'
    },
    {
        id: 2,
        title: 'Khách dễ tính',
        number: '1.111'
    },

]
export const arraySelectDay = [
    {
        id: 1,
        title: 'Hôm nay',
    },
    {
        id: 2,
        title: 'Ngày mai',
    },
    {
        id: 3,
        title: 'Ngày mốt',
    },
    {
        id: 4,
        title: 'Tuần này',
    }, {
        id: 5,
        title: 'Tháng này',
    },
    {
        id: 6,
        title: 'Quý này',
    },
]
export const arrayArea = [
    {
        id: 1,
        title: 'Miền Bắc',
        number: '11.000'
    },
    {
        id: 2,
        title: 'Miền Trung',
        number: '11.200'
    },
    {
        id: 3,
        title: 'Miền Nam',
        number: '11.500'
    },

]
export const arrayRelatives = [
    {
        id: 1,
        title: 'Mẹ',
        name: 'Nguyễn Thu Hoài',
        interest: [
            {
                id: 1,
                interest: 'Âm nhạc'
            },
            {
                id: 2,
                interest: 'Thể thao'
            }
        ]
    },
    {
        id: 1,
        title: 'Cha',
        name: 'Anh Phạm',
        interest: [
            {
                id: 1,
                interest: 'Âm nhạc'
            },
            {
                id: 2,
                interest: 'Thể thao'
            }
        ]
    }
]