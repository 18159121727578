import React, { useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import './BreadcrumbBlock.scss'

interface Props {
  items: Array<any>;
  title: string;
  backLink: string;
}

const BreadcrumbBlock: React.FC<Props> = ({items, title, backLink}) => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);
  return (
    <div className="app-breadcrumb-block">
      <Breadcrumb
        items={items}
      />

      <div className="app-breadcrumb-title">
        {
          backLink
            ? <Link to={backLink}><ArrowLeftOutlined /></Link>
            : null
        }
        <div className="title">{title}</div>
      </div>
    </div>
  );
}

export default BreadcrumbBlock;