import React from "react";
import RightPopup from "../../../components/Popup/RightPopup";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import './VehicleInfoPopup.scss';

interface Props {
  className?: string;
  onClose?: () => void;
  onShowHistory?: () => void;
}

const VehicleInfoPopup: React.FC<Props> = ({ className, onClose, onShowHistory }) => {
  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleOnShowHistory = () => {
    if (onShowHistory) {
      onShowHistory()
    }
  }

  return (
    <RightPopup
      title="Thông tin xe"
      className={className}
      contentClassName="vehicle-info-popup__content"
      onClose={handleOnClose}
    >
      <img
        src="/images/bmw-series-7.svg"
        alt="bmw-series-7"
        className="avatar"
      />

      <div className="block">
        <div className="title">Thông tin mua bán</div>
        <div className="item">
          <span>Đại lý bán</span>
          <span className="neutral-gray-400">THACO AUTO Bình Dương</span>
        </div>
        <div className="item">
          <span>Mã đơn hàng</span>
          <span className="neutral-gray-400">381822344</span>
        </div>
        <div className="item">
          <span>Số hợp đồng</span>
          <span className="neutral-gray-400">TC12381822344</span>
        </div>
        <div className="item">
          <span>Mã xe</span>
          <span className="neutral-gray-400">822344</span>
        </div>
        <div className="item">
          <span>Ngày sở hữu</span>
          <span className="neutral-gray-400">13:00 23/03/2023</span>
        </div>
      </div>

      <div className="block">
        <div className="title">Thông tin bảo hành & bảo dưỡng</div>
        <div className="item">
          <span>Bảo hành từ</span>
          <span className="neutral-gray-400">13:00 23/03/2023</span>
        </div>
        <div className="item">
          <span>Thời hạn bảo hành</span>
          <span className="neutral-gray-400">1 năm <span className="success-600">(13:00 23/03/2024)</span></span>
        </div>
        <div className="item">
          <span>Số KM bảo dưỡng gần nhất</span>
          <span className="neutral-gray-400">10.535</span>
        </div>
        <div className="item">
          <span>Ngày bảo dưỡng gần nhất</span>
          <span className="neutral-gray-400">27/12/2023</span>
        </div>
        <div className="item">
          <Button
            block
            className="rs-button primary-outline"
            onClick={handleOnShowHistory}
          >
            <span>Xem lịch sử bảo dưỡng</span>
            <RightOutlined />
          </Button>
        </div>
      </div>

      <div className="block">
        <div className="title">Chi tiết thông tin xe</div>
        <div className="item">
          <span>Xuất xứ</span>
          <div className="item-right flag">
            <span className="neutral-gray-400">Việt Nam</span>
            <img src="/images/flags/vi.svg" alt="" />
          </div>
        </div>
        <div className="item">
          <span>Thương hiệu</span>
          <div className="item-right brand">
          <span className="neutral-gray-400">BMW</span>
            <img src="/images/logo-partner.svg" alt="" />
          </div>
        </div>
        <div className="item">
          <span>Màu xe</span>
          <span className="neutral-gray-400">Đen</span>
        </div>
        <div className="item">
          <span>Kiểu dáng</span>
          <span className="neutral-gray-400">Hatchback</span>
        </div>
        <div className="item">
          <span>Nhiên liệu</span>
          <span className="neutral-gray-400">Xăng</span>
        </div>
        <div className="item">
          <span>Số khung</span>
          <span className="neutral-gray-400">381822344</span>
        </div>
        <div className="item">
          <span>Số máy</span>
          <span className="neutral-gray-400">381822344</span>
        </div>
      </div>
    </RightPopup>
  )
}

export default VehicleInfoPopup