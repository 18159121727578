import React from 'react'
import RelativeItem from './RelativeItem'
import './RelativeList.scss'

interface Props {
  items: Array<any>,
}

const RelativeList: React.FC<Props> = ({
  items
}) => {
  return (
    <div className="relative-list">
      {
        items.map((item, index) => (
          <RelativeItem 
            key={index}
            type={item.type}
            name={item.name}
            interests={item.interests}
          />
        ))
      }
    </div>
  )
}

export default RelativeList;