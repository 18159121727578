import React, { useEffect } from 'react';
import Layout from '../../Layout';

const Units: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);
  return (
    <Layout>
      <div>Đơn vị</div>
    </Layout>
  );
}

export default Units;